<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4" v-if="currentUser">
        <h3>{{ data.spot.instId }}</h3>
         <table class="table table-sm table-stripped table-hover" style="">
          <thead class="thead-dark">
            <tr>
              <th>Price</th> 
              <th>Size USD</th> 
              <th>Size</th> 
              <th>Liq. Orders</th>
              <th>Orders #</th> 
            </tr>
          </thead> 
          <tbody>
            <tr v-for="(asks, index) in this.data.spot.asks" v-bind:key="index" class="asks">
              <td>{{asks[0]}}</td>
              <td>{{(asks[0] * asks[1]).toFixed(3)}}</td>
              <td>{{asks[1]}}</td>
              <td>{{asks[2]}}</td>
              <td>{{asks[3]}}</td>
            </tr>
            <tr>
              <td colspan="4"><strong>{{ data.spot.price?.toFixed(12) }}</strong></td>
            </tr>
            <tr v-for="(bids, index) in this.data.spot.bids" v-bind:key="index"  class="bids">
              <td>{{bids[0]}}</td>
              <td>{{(bids[0] * bids[1]).toFixed(3)}}</td>
              <td>{{bids[1]}}</td>
              <td>{{bids[2]}}</td>
              <td>{{bids[3]}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-4" v-if="currentUser">
        <h3>{{ data.swap.instId }}</h3>
         <table class="table table-sm table-stripped table-hover" style="">
          <thead class="thead-dark">
            <tr>
              <th>Price</th>  
              <th>Size</th> 
              <th>Liq. Orders</th>
              <th>Orders #</th> 
            </tr>
          </thead> 
          <tbody>
            <tr v-for="(asks, index) in this.data.swap.asks" v-bind:key="index" class="asks">
              <td>{{asks[0]}}</td>
              <td>{{asks[1]}}</td>
              <td>{{asks[2]}}</td>
              <td>{{asks[3]}}</td>
            </tr>
            <tr>
              <td colspan="4"><strong>{{ data.swap.price.toFixed(12) }}</strong></td>
            </tr>
            <tr v-for="(bids, index) in this.data.swap?.bids" v-bind:key="index"  class="bids">
              <td>{{bids[0]}}</td>
              <td>{{bids[1]}}</td>
              <td>{{bids[2]}}</td>
              <td>{{bids[3]}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-4" v-if="currentUser">
        <h3>Entry Price</h3>
        <h4>{{ ((Number(data.spot.asks.at(-1)[0]) - Number(data.swap.bids[0][0])) *1000000000000).toFixed(2) }}</h4>
        <h3>Exit Price</h3>
        <h4>{{ ((Number(data.spot.bids[0][0]) - Number(data.swap.asks.at(-1)[0])) *1000000000000).toFixed(2) }}</h4>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  props: ['data'],
  components: { },
  data() {
    return {
      
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods:{
    
  },
  mounted() {
    
  }
};
</script>

<style scoped>
  ul {
    padding-left: 0;
  }

  .clickable {
    cursor: pointer;
  }

  .asks {
    color: darkgreen;
  }

  .bids{
    color: crimson;
  }
</style>