<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <div class="container-fluid">
        <div class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link to="/home" class="nav-link">
              <font-awesome-icon icon="home" />
            </router-link>
          </li>
        </div>

        <div v-if="!currentUser" class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link to="/register" class="nav-link">
              <font-awesome-icon icon="user-plus" /> Sign Up
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              <font-awesome-icon icon="sign-in-alt" /> Login
            </router-link>
          </li>
        </div>

        <div v-if="currentUser" class="navbar-nav ms-auto">
          <span class="navbar-text">
            {{`${currentUser.username}`}}          
          </span>
          <li class="nav-item">
            <router-link to="/profile" class="nav-link">
              <font-awesome-icon icon="user" />
            </router-link>
          </li>
        </div>
      </div>
    </nav>

    
    <router-view :data="data" />
    
  </div>
</template>

<script>
//const CryptoJS = require('crypto-js');

export default {
  data() {
    return {
      data: {
        spot: {
          instId: 'KISHU-USDT',
        },
        swap: {
          instId: 'KISHU-USDT-SWAP'
        }
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ADMIN');
      }
      return false;
    }
  },
  mounted() {
    //const wss = new WebSocket('wss://ws.okex.com:8443/ws/v5/private');
    const wss = new WebSocket('wss://ws.okex.com:8443/ws/v5/public');
    
    /*
    let api_key = "6a0a108b-fccb-4aef-9d6b-634e226a5b15"
    let secret_key = "C8E041B947A92ED724CFEFB7F84122E9"
    let passphrase = "Galinheta48"
    let timestamp = '' + Date.now() /1000;
    let sign = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(timestamp +'GET'+'/users/self/verify', secret_key))
    */

    wss.onopen = (ev) => {
      console.log(ev);
      
      /*
      wss.send(JSON.stringify({
        "op": "login",
        "args": [
          {
            "apiKey": api_key,
            "passphrase": passphrase,
            "timestamp": timestamp,
            "sign": sign
          }
        ]
      }));
      */

      wss.send(JSON.stringify(
        {
          "op": "subscribe",
          "args": [
            {
              "channel": "books5",
              "instId": "KISHU-USDT"
            },
            {
              "channel": "mark-price",
              "instId": "KISHU-USDT"
            },
            {
              "channel": "books5",
              "instId": "KISHU-USDT-SWAP"
            },
            {
              "channel": "mark-price",
              "instId": "KISHU-USDT-SWAP"
            }
          ]
        }
      ));
    }

    wss.onmessage = (ev) =>{
      let d = JSON.parse(ev.data);
      switch (d?.arg.channel){
      case "mark-price":
        if(this.data.spot.instId == d?.arg.instId)
        {
          this.data.spot.price = Number(d?.data[0].markPx);
        }
        else {
          this.data.swap.price = Number(d?.data[0].markPx);
        }
        break;
      case "books5":
        if(this.data.spot.instId == d?.arg.instId)
        {
          this.data.spot.asks = d?.data[0].asks.reverse();
          this.data.spot.bids = d?.data[0].bids;
        }
        else {
          this.data.swap.asks = d?.data[0].asks.reverse();
          this.data.swap.bids = d?.data[0].bids;
        }
        break;
        default:
          break;
      }
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
  
</style>